.leafs_wrap {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    overflow: hidden;
    pointer-events: none;

    .leaf {
        box-shadow: none;
        height: 25px;
        position: absolute;
        width: 25px;

        img {
            width: 100%;
        }

        &.x1 {
            animation: animateLeaf 15s linear infinite, sideWays 2s ease-in-out infinite alternate;
            left: 6%;
            top: 45%;
        }

        &.x2 {
            animation: animateLeaf 10s linear infinite, sideWays 4s ease-in-out infinite alternate;
            left: 5%;
            top: 80%;
        }

        &.x3 {
            animation: animateLeaf 18s linear infinite, sideWays 2s ease-in-out infinite alternate;
            left: 10%;
            top: 40%;
        }

        &.x4 {
            animation: animateLeaf 12s linear infinite, sideWays 3s ease-in-out infinite alternate;
            left: 20%;
            top: 0;
        }

        &.x5 {
            animation: animateLeaf 19s linear infinite, sideWays 4s ease-in-out infinite alternate;
            left: 30%;
            top: 50%;
        }

        &.x6 {
            animation: animateLeaf 11s linear infinite, sideWays 2s ease-in-out infinite alternate;
            left: 50%;
            top: 0;
        }

        &.x7 {
            animation: animateLeaf 10s linear infinite, sideWays 2s ease-in-out infinite alternate;
            left: 65%;
            top: 70%;
        }

        &.x8 {
            animation: animateLeaf 12s linear infinite, sideWays 3s ease-in-out infinite alternate;
            left: 80%;
            top: 10%;
        }

        &.x9 {
            animation: animateLeaf 19s linear infinite, sideWays 4s ease-in-out infinite alternate;
            left: 90%;
            top: 50%;
        }

        &.x10 {
            animation: animateLeaf 16s linear infinite, sideWays 2s ease-in-out infinite alternate;
            left: 80%;
            top: 80%;
        }
    }
}

@keyframes animateLeaf {
    0% {
        margin-top: 20%;
        rotate: 0deg;
        opacity: 0;
    }

    10% {
        opacity: 0.5;
    }

    90% {
        opacity: 0.5;
    }

    100% {
        margin-top: -30%;
        rotate: 180deg;
        opacity: 0;
    }
}

@keyframes sideWays {
    0% {
        margin-left: 0;
    }

    100% {
        margin-left: 25px;
    }
}