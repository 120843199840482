@import url('https://fonts.googleapis.com/css?family=Montserrat:400,600,700');
@import url('https://fonts.googleapis.com/css?family=Catamaran:400,800');
@import "../../styles/variable";

.error-container {
    text-align: center;
    font-size: 106px;
    font-family: 'Catamaran', sans-serif;
    font-weight: 800;
    margin: 100px 15px;

    span {
        display: inline-block;
        position: relative;

        &.four {
            width: 136px;
            height: 43px;
            border-radius: 999px;
            background:
                linear-gradient(140deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.07) 43%, transparent 44%, transparent 100%),
                linear-gradient(105deg, transparent 0%, transparent 40%, rgba(0, 0, 0, 0.06) 41%, rgba(0, 0, 0, 0.07) 76%, transparent 77%, transparent 100%),
                linear-gradient(to right, #6fa68d, #68a689);

            &:before,
            &:after {
                content: '';
                display: block;
                position: absolute;
                border-radius: 999px;
            }

            &:before {
                width: 43px;
                height: 156px;
                left: 60px;
                bottom: -43px;
                background:
                    linear-gradient(128deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.07) 40%, transparent 41%, transparent 100%),
                    linear-gradient(116deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.07) 50%, transparent 51%, transparent 100%),
                    linear-gradient(to top, #4a596b, #567a8e, #5f8f93, #659e7b, #67a689);
            }

            &:after {
                width: 137px;
                height: 43px;
                transform: rotate(-49.5deg);
                left: -18px;
                bottom: 36px;
                background: linear-gradient(to right, #4a596b, #567a8e, #5f8f93, #659e7b, #67a689);
            }
        }

        &.zero {
            vertical-align: text-top;
            width: 156px;
            height: 156px;
            border-radius: 999px;
            background: linear-gradient(-45deg, transparent 0%, rgba(0, 0, 0, 0.06) 50%, transparent 51%, transparent 100%),
                linear-gradient(to top right, #4a596b, #4a596b, #567a8e, #5f8f93, #659e7b, #6f9c75, #6f9c75);
            overflow: hidden;
            animation: bgshadow 5s infinite;

            &:before {
                content: '';
                display: block;
                position: absolute;
                transform: rotate(45deg);
                width: 90px;
                height: 90px;
                background-color: transparent;
                left: 0px;
                bottom: 0px;
                background:
                    linear-gradient(95deg, transparent 0%, transparent 8%, rgba(0, 0, 0, 0.07) 9%, transparent 50%, transparent 100%),
                    linear-gradient(85deg, transparent 0%, transparent 19%, rgba(0, 0, 0, 0.05) 20%, rgba(0, 0, 0, 0.07) 91%, transparent 92%, transparent 100%);
            }

            &:after {
                content: '';
                display: block;
                position: absolute;
                border-radius: 999px;
                width: 70px;
                height: 70px;
                left: 43px;
                bottom: 43px;
                background: #FDFAF5;
                box-shadow: -2px 2px 2px 0px rgba(0, 0, 0, 0.1);
            }
        }
    }

    .screen-reader-text {
        position: absolute;
        top: -9999em;
        left: -9999em;
    }

    h3,
    a {
        margin-top: 50px;
        font-size: 1.5rem;
        font-weight: 600;
        text-align: center;
        color: rgb(132, 124, 124);

        @media (max-width: 1281px) {
            display: block;
            width: 80%;
            margin: 0 auto;
            font-size: 1rem;
            margin-top: 20px;
        }
    }

    a {
        padding: 10px;
        border-radius: 7px;
        background-color: $green;
        color: #efe4d4;

        &:hover {
            opacity: 0.8;
        }

        &:active {
            opacity: 0.6;
        }
    }
}

@keyframes bgshadow {
    0% {
        box-shadow: inset -160px 160px 0px 5px rgba(0, 0, 0, 0.4);
    }

    45% {
        box-shadow: inset 0px 0px 0px 0px rgba(0, 0, 0, 0.1);
    }

    55% {
        box-shadow: inset 0px 0px 0px 0px rgba(0, 0, 0, 0.1);
    }

    100% {
        box-shadow: inset 160px -160px 0px 5px rgba(0, 0, 0, 0.4);
    }
}
