@import "../../../styles/variable";
@import "../../../styles/mixins";

.introduction {
    &-image {
        width: 40%;

        img {
            width: 100%;
            border-radius: 20px;
        }
    }

    &-content {
        width: 55%;
        padding: 40px;

        h2,
        span {
            font-size: 2.5rem;
            letter-spacing: -1px;
            font-weight: 700;
            margin-bottom: 20px;
        }

        span {
            margin-left: 0.45rem;
            color: $oliveGreen;
        }

        p {
            font-size: 1.125rem;
            font-weight: 600;
            text-align: justify;
        }
    }
}

@media screen and (max-width: 1281px) {
    .introduction {
        &-image {
            width: 100%;
            border-radius: 8px;
        }

        &-content {
            width: 100%;
            padding: 0;

            h2,
            span {
                font-size: 1.8rem;
                line-height: 2rem;
                height: 3rem;
                margin-top: 10px;
            }

            span {
                margin-left: 7px;
                color: $oliveGreen;
            }

            p {
                font-size: 0.9rem;
            }
        }
    }
}