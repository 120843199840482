@import '../../../styles/variable';
@import '../../../styles/mixins';
@import 'https://fonts.googleapis.com/css?family=Oswald';

.product-desktop-carousel {
    @include flex($align-items: center, $gap: 1rem);


    .button {
        padding: 0.5rem 1rem;
        border-radius: 50%;
        background-color: $green;
        color: white;
        border: none;

        &:hover {
            opacity: 0.8;
        }

        &:active {
            opacity: 0.6;
        }
    }

    .product-list {
        display: grid;
        grid-template-columns: repeat(5, minmax(0, 1fr));
        gap: 1rem;
        width: 91.666667%;

    }
}

.product-mobile-carousel{
    .carousel{
        padding: 5px 0;
        cursor: grab;
        overflow: hidden;
        border-radius: 5px;
        .inner-carousel {
            transform: translate3d(0, 0, 0);
            @include flex($align-items: center, $gap: 1.5rem);
        }
    }
}