@import "../../../styles/variable";
@import "../../../styles/mixins";

@media (min-width: 1281px) {
    header {
        position: sticky;
        top: 0;
        left: 0;
        background-color: $backgroundGreen;
        padding: 10px;
        width: 100%;
        border-radius: 0 0 10px 10px;
        z-index: 100;
        display: flex;
        background-image: url("../../../assets/images/header-background.png");
        background-repeat: no-repeat;
        background-size: 1800px;
        background-position: center -790px;

        .logo {
            margin-left: 180px;

            img {
                width: 120px;
                height: 120px;
            }
        }

        ul {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            gap: 20px;
            padding-right: 280px;

            li {
                a {
                    padding: 10px 18px;
                    border-radius: 10px;
                    color: $background;
                    font-size: 18px;
                    font-weight: 600;
                    transition-duration: 0.5s;

                    &:hover,
                    &.active {
                        color: white;
                        background-color: $oliveGreen;
                    }
                }
            }
        }

        &.header-mobile {
            display: none;
        }
    }
}

@media (max-width: 1281px) {
    header{
        &.header-desktop {
            display: none;
        }
        &.header-mobile {
            position: fixed;
            top: 0;
            left: 0;
            z-index: 100;
            .header {
                width: 100%;
                padding: 0 25px;
                @include flex($justify-content: space-between, $align-items: center);
                height: 100px;
                width: 100vw;
                background-color: $backgroundGreen;
    
                .logo {
                    height: 100%;
    
                    img {
                        height: 100%;
                        aspect-ratio: 1/1;
                    }
                }
    
                .mobile-menu{
                    svg{
                        width: 25px;
                        height: 25px;
                    }
                }
    
            }
    
            ul {
                position: absolute;
                padding: 20px;
                top: 100px;
                right: 0;
                height: calc(100vh - 100px);
                background-color: rgba(102, 175, 150, 0.9);
                @include flex($flex-direction: column, $gap: 30px, $align-items: center);
                overflow: hidden;

                li {
                    a {
                        padding: 10px 18px;
                        border-radius: 10px;
                        color: $background;
                        font-size: 16px;
                        font-weight: 600;
    
                        &:hover,
                        &.active {
                            color: white;
                            background-color: $oliveGreen;
                        }
                    }
                }
            }
            
        }
    }
}