@import 'https://fonts.googleapis.com/css?family=Nunito';

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Nunito", sans-serif;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
}

body {
    position: relative;
    font-size: 16px;
    width: 100vw;
    overflow-x: hidden;
}

a {
    text-decoration: none;
}

ul {
    list-style: none;
}

button,
a {
    @include cantSelect();
}

@media (max-width: 1281px) {
    body {
        font-size: 12px;
    }
}