@import '../../styles/variable';
@import '../../styles/mixins';
@import 'https://fonts.googleapis.com/css?family=Oswald';

.product-desktop {
    margin: 10px 10% 100px;


    h2{
        font-size: 34px;
        color: $oliveGreen;
        font-weight: 700;
        letter-spacing: -1px;
    }
}
@media (max-width: 1281px){
    .product-mobile {
        display: block;
        margin: 120px 4% 100px;
        h2{
            font-size: 1.5rem;
            color: $oliveGreen;
            font-weight: 800;
            letter-spacing: -1px;
        }
    }

}