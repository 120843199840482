@mixin flex($display: flex, $justify-content: flex-start, $align-items: flex-start, $flex-direction: row, $gap: 0px, $flex-wrap: nowrap) {
    display: $display;
    justify-content: $justify-content;
    align-items: $align-items;
    flex-direction: $flex-direction;
    gap: $gap;
    flex-wrap: $flex-wrap;
}

@mixin cantSelect() {
    -moz-user-select: none !important;
    -webkit-touch-callout: none !important;
    -webkit-user-select: none !important;
    -khtml-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
}