$green: #66AF96;
$background: #fff;
$blackRussian: #151618;
$oliveGreen: #538e79;
$bourbon: #b07946;
$backgroundGreen: #66AF96;

$boxShadow: 0 4px 6px 2px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);

$facebook: #1178f2;
$shopee: #f1582c;
$lazada: #070286;