@import "../../../styles/variable";
@import "../../../styles/mixins";

footer {
    padding: 15px 0;
    width: 100%;
    background-color: $backgroundGreen;
    position: relative;
    bottom: 0;

    .footer_content {
        @include flex($justify-content: space-around, $align-items: center);

        .logo {
            width: 30%;
            @include flex($justify-content: center, $align-items: center);

            img {
                width: 240px;
            }
        }
    }

    .about-me {
        width: 25%;
        @include flex($justify-content: center, $align-items: center, $flex-direction: column);

        .title {
            font-size: 2rem;
            font-weight: 600;
            color: white;
            margin-bottom: 5px;
        }

        .description {
            font-size: 1rem;
            color: white;
            line-height: 1.1;
            text-align: justify;
        }
    }

    .information {
        padding: 30px 50px;

        .item {
            margin-bottom: 20px;
            @include flex($align-items: center);
            color: white;

            svg {
                font-size: 20px;
                padding: 10px;
                border-radius: 50%;
                background-color: $oliveGreen;
            }

            span {
                margin-left: 10px;
                font-size: 17px;
                font-weight: 600;
            }
        }

        ul {
            @include flex($justify-content: center, $gap: 25px);

            li {
                svg {
                    color: white;
                    font-size: 22px;
                    padding: 8px;
                    border-radius: 50%;
                    background-color: $oliveGreen;
                }

                img {
                    width: 38px;
                    height: 38px;
                    padding: 8px;
                    border-radius: 50%;
                    background-color: $oliveGreen;
                }
            }
        }
    }
}

@media (max-width: 1281px) {
    footer {
        .footer_content{
            flex-direction: column;
            .logo{
                width: 100%;
            }
        }
        .about-me {
            width: 83%;
        }

        .information{
            padding: 10px 0;
            ul {
                padding: 0;
                margin-top: 10px;
                justify-content: center;
                gap: 30px;
                li {
                    svg {
                        font-size: 30px;
                        padding: 15px;
                    }
                    img {
                        width: 57px;
                        height: 57px;
                    }
                }
            }

            .item {
                @include flex($align-items: center);
                color: white;
    
                svg {
                    font-size: 14px;
                    padding: 10px;
                    border-radius: 50%;
                    background-color: $oliveGreen;
                }
    
                span {
                    margin-left: 10px;
                    font-size: 17px;
                    font-weight: 600;
                }
            }
        }
    }
}