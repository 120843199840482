@import '../../../styles/variable';
@import '../../../styles/mixins';
@import 'https://fonts.googleapis.com/css?family=Oswald';

.product-display-desktop {
    padding: 50px 30px;
    margin-bottom: 40px;
    border-radius: 15px;
    @include flex($justify-content: center, $gap: 70px);
    box-shadow: $boxShadow;
    overflow: hidden;
    position: relative;

    .img-container {
        padding: 2.5rem;
        width: 37%;
        max-width: 1400px;
        height: 580px;
        position: relative;

        .img-display {
            border-radius: 1rem;
            width: 100%;
            height: 100%;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            transition-duration: 0.8s;
            background-color: rgba(157, 177, 188, 0.3);
            z-index: -1;

            &:hover {
                transform: scale(1.2);
            }
        }

        .button {
            position: absolute;
            padding: 0.3rem 1rem;
            border-radius: 50%;
            font-size: 1.5rem;
            line-height: 2rem;
            color: #ffffff;
            background-color: rgba(0, 0, 0, 0.6);
            cursor: pointer;
            top: 45%;

            &:hover {
                background-color: rgba(0, 0, 0, 0.4);
            }

            &-prev {
                left: 1.5rem;
            }

            &-next {
                right: 1.5rem;
            }
        }

        .change {
            &-group {
                @include flex($justify-content: center, $gap: 1rem);
                top: 1rem;
                padding: 0.5rem 0;
                width: 50%;
                margin: 0 auto;
            }

            &-item {
                color: rgba(0, 0, 0, 0.6);
                font-size: 0.875rem;
                line-height: 1.25rem;
                cursor: pointer;
                z-index: 1;
            }
        }

    }

    .product-info {
        width: 60%;
        height: 100%;
        padding-right: 40px;

        .long-line {
            width: 50%;
            height: 2px;
            margin: 15px auto;
            background-color: $green;
            border-radius: 50%;
        }

        .short-line {
            width: 25%;
            height: 1px;
            margin: 8px auto;
            background-color: $green;
            border-radius: 50%;
        }

        h1 {
            margin-top: 20px;
            margin-bottom: 15px;
            font-size: 50px;
            line-height: 50px;
            font-weight: 600;
            font-family: 'Oswald', sans-serif;
            text-align: center;
        }

        p {
            margin-bottom: 10px;
            font-size: 18px;
            line-height: 25px;
            font-weight: 200;
            font-family: 'Oswald', sans-serif;
            text-align: center;
        }

        .price {
            font-size: 30px;
            font-weight: 700;
            font-family: 'Oswald', sans-serif;
            margin-bottom: 15px;
            color: rgb(248 113 113);

            &:first-child {
                margin-bottom: 5px;
            }
        }


        h4 {
            margin-top: 35px;
            font-size: 20px;
            font-weight: 600;
            color: rgb(87, 87, 87);
        }

        .description {
            margin: 10px 0px 10px 17px;
            font-size: 16px;
            line-height: 20px;
            list-style: disc;

            &:first-child {
                margin-top: 10px;
            }

            &:last-child {
                margin-bottom: 10px;
            }
        }

        .contact-link {
            padding: 10px 0;
            @include flex($align-items: center, $gap: 20px);
            
            a {
                width: 30%;
                padding: 10px 20px;
                border-radius: 10px;
                font-size: 18px;
                font-weight: 400;
                color: white;
                @include flex($align-items: center, $gap: 20px);

                &.facebook {
                    background-color: darken($facebook, 15%);
                }

                &.shopee {
                    background-color: darken($shopee, 15%);
                }

                &.lazada {
                    background-color: darken($lazada, 10%);
                }

                &:hover {
                    opacity: 0.8;
                }
            }
        }
    }
}


// MOBILE VIEW

.product-display-mobile {
    margin-bottom: 1rem;
    .img-container {
        width: 100%;
        height: 50vh;
        position: relative;

        .img-display {
            width: 100%;
            height: 100%;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            transition-duration: 0.5s;
            background-color: rgba(157, 177, 188, 0.3);
            z-index: -1;

            &:hover {
                transform: scale(1.2);
            }
        }

        .button {
            position: absolute;
            padding: 0.3rem 1rem;
            border-radius: 50%;
            font-size: 1rem;
            line-height: 2rem;
            color: #ffffff;
            background-color: rgba(0, 0, 0, 0.6);
            cursor: pointer;
            top: 45%;

            &:hover {
                background-color: rgba(0, 0, 0, 0.4);
            }

            &-prev {
                left: 0rem;
            }

            &-next {
                right: 0rem;
            }
        }

        .change {
            &-group {
                @include flex($justify-content: center, $gap: 1rem);
                position: absolute;
                bottom: 0;
                padding: 0.5rem 0;
                width: 100%;
                margin: 0 auto;
            }

            &-item {
                color: rgba(0, 0, 0, 0.6);
                font-size: 1rem;
                line-height: 1.25rem;
                cursor: pointer;
                z-index: 1;
            }
        }

    }

    .product-info {
        height: 100%;

        .long-line {
            width: 50%;
            height: 2px;
            margin: 7px auto;
            background-color: $green;
            border-radius: 50%;
        }

        .short-line {
            width: 25%;
            height: 1px;
            margin: 3px auto;
            background-color: $green;
            border-radius: 50%;
        }

        h1 {
            margin-top: 10px;
            margin-bottom: 10px;
            font-size: 1.5rem;
            line-height: 2rem;
            font-weight: 600;
            font-family: 'Oswald', sans-serif;
            text-align: center;
        }

        p {
            margin-bottom: 10px;
            font-size: 1rem;
            line-height: 25px;
            font-weight: 200;
            font-family: 'Oswald', sans-serif;
            text-align: center;
        }

        .price {
            font-size: 1.5rem;
            font-weight: 700;
            font-family: 'Oswald', sans-serif;
            margin-bottom: 15px;
            color: rgb(248 113 113);

            &:first-child {
                margin-bottom: 5px;
            }
        }


        h4 {
            margin-top: 1rem;
            font-size: 1.2rem;
            font-weight: 600;
            color: rgb(87, 87, 87);
        }

        .description {
            margin: 10px 0px;
            font-size: 1rem;
            line-height: 1.2rem;
            list-style: disc;

            &:first-child {
                margin-top: .5rem;
            }

            &:last-child {
                margin-bottom: .5rem;
            }
        }

        .contact-link {
            width: 90%;
            @include flex($align-items: center, $justify-content: space-between, $gap: 1rem);
            flex-wrap: wrap;
            a {
                width: 50%;
                padding: .6rem;
                border-radius: 5px;
                font-size: 1.125rem;
                font-weight: 400;
                color: white;
                @include flex($align-items: center, $gap: 1.125rem);

                &.facebook {
                    background-color: darken($facebook, 15%);
                }

                &.shopee {
                    background-color: darken($shopee, 15%);
                }

                &.lazada {
                    background-color: darken($lazada, 10%);
                }

                &:hover {
                    opacity: 0.8;
                }
            }
        }
    }
    .divider{
        width: 100%;
        height: 1px;
        background-color: #e2e8f0;
        margin: 1rem 0;
    }
}
