@import "../../../styles/variable";
@import "../../../styles/mixins";
@import "https://fonts.googleapis.com/css?family=Coiny";

.home-carousel {
    @include flex($justify-content: center, $align-items: center);
    height: max-content;

    .carousel-intro {
        width: 40%;

        span {
            line-height: 20px;
            font-weight: 400;
            font-size: 1.125rem;
        }

        .carousel-title {
            margin-left: 10px;
            font-size: 2.5rem;
            font-weight: 700;
            letter-spacing: -1px;
            line-height: 2.5rem;
            margin-bottom: 10px;
            color: $oliveGreen;
        }
        p{
            font-size: 1.125rem;
            font-weight: 400;
            line-height: 1.25rem;
            margin-bottom: 10px;
        }
    }

    .carousel {
        width: 60%;
        position: relative;

        img{
            width: 100%;
            aspect-ratio: 16/9;
            -webkit-filter: blur(1px) brightness(0.5);
            -moz-filter: blur(1px) brightness(0.5);
            -ms-filter: blur(1px) brightness(0.5);
            -o-filter: blur(1px) brightness(0.5);
            filter: blur(1px) brightness(0.5);
            
        }
        .carousel-caption {
            height: 100%;
            @include flex($flex-direction: column ,$justify-content: center, $align-items: center);
            h3 {
                text-align: center;
                font-size: 28px;
                font-family: 'Coiny', sans-serif;
            }

            p {
                text-align: justify;
                font-size: 20px;
                font-weight: 400;
                line-height: 25px;
                margin-bottom: 10px;
            }
        }
    }
}

@media screen and (max-width: 1281px) {
    .home-carousel {
        flex-direction: column;
        height: max-content;

        .carousel-intro {
            width: 100%;
            margin-bottom: 10px;

            span {
                line-height: 1rem;
                font-weight: 400;
                font-size: 1.125rem;
            }
    
            .carousel-title {
                margin-left: 5px;
                font-size: 1.8rem;
                font-weight: 700;
                letter-spacing: -1px;
                line-height: 2.5rem;
                margin-bottom: 10px;
                color: $oliveGreen;
            }
            p{
                font-size: 1rem;
                font-weight: 400;
                line-height: 1.25rem;
                margin-bottom: 10px;
            }
        }

        .carousel {
            width: 100%;

            img{
                width: 100%;
                aspect-ratio: 1;
                -webkit-filter: blur(1px) brightness(0.5);
                -moz-filter: blur(1px) brightness(0.5);
                -ms-filter: blur(1px) brightness(0.5);
                -o-filter: blur(1px) brightness(0.5);
                filter: blur(1px) brightness(0.5);
                
            }

            .carousel-caption {
                height: 100%;
                @include flex($flex-direction: column ,$justify-content: center, $align-items: center);
                h3 {
                    text-align: center;
                    font-size: 16px;
                    font-family: 'Coiny', sans-serif;
                }
    
                p {
                    text-align: justify;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 14px;
                    margin-bottom: 10px;
                }
            }
        }
    }
    
}