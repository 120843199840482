@import "../../../styles/mixins";
@import "../../../styles/variable";
@import 'https://fonts.googleapis.com/css?family=Oswald';

.home-product-item {
    overflow: hidden;
    grid-column: span 1;
    width: 100%;
    @include flex($flex-direction: column, $align-items: center, $justify-content: space-between);
    border-radius: 0.75rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);

    .img-wrapper {
        width: 100%;
        aspect-ratio: 1.35/1;
        overflow: hidden;
        background-color: #9db1bc;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    &:hover {
        img {
            transform: scale(1.2);
            transition: transform 1s ease-in-out;
        }
    }

    .product-description {
        margin-top: 10px;
        width: 100%;
        @include flex($flex-direction: column, $justify-content: center);

        h3,
        h4 {
            width: 100%;
            text-align: center;
        }

        h3 {
            font-size: 1.1rem;
            font-weight: 700;
            margin-bottom: 5px;
        }

        h4 {
            font-size: 1.2rem;
            font-weight: 800;
            font-family: 'Oswald', sans-serif;
            color: rgb(248 113 113);
        }

        span {
            width: 100%;
            font-size: 0.9rem;
            font-weight: 400;
            color: #454444;
            text-align: center;
        }
    }

    button {
        padding: 0.5rem 2rem;
        margin: 1rem 0;
        border: transparent;
        border-radius: 10px;
        color: white;
        background-color: $green;
        @include flex($justify-content: flex-end);

        &:hover {
            opacity: 0.8;
        }

        &:active {
            opacity: 0.6;
        }
    }

}

@media screen and (max-width: 1281px) {
    .home-product-item {
        width: 100%;
        grid-column: span 1;

        .img-wrapper {
            aspect-ratio: 1.5/1;
        }

        .product-description {
            margin-top: 10px;
            width: 100%;
            @include flex($flex-direction: column, $justify-content: space-around);

            h3,
            h4 {
                width: 85%;
                margin: auto;
                text-align: center;
            }

            h3 {
                font-size: 0.9rem;
                height: 2rem;
                font-weight: 700;
                margin-bottom: 5px;
            }

            h4 {
                font-size: 0.8rem;
                font-weight: 800;
                font-family: 'Oswald', sans-serif;
                color: rgb(248 113 113);
            }

            span {
                width: 100%;
                font-size: 0.7rem;
                font-weight: 400;
                color: #454444;
                text-align: center;
            }
        }

        button {
            padding: 0.4rem 2rem;
            margin: 0.5rem 0 0.5rem;
            border: transparent;
            border-radius: 5px;
            color: white;
            background-color: $green;
            @include flex($justify-content: flex-end);

            &:hover {
                opacity: 0.8;
            }

            &:active {
                opacity: 0.6;
            }
        }
    }
}