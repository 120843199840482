@import "../../../../styles/variable";
@import "../../../../styles/mixins";
@import 'https://fonts.googleapis.com/css?family=Chivo';

.product-item {
    overflow: hidden;
    grid-column: span 1;
    width: 100%;
    border-radius: 0.75rem;
    padding: 1rem 0.5rem;
    @include flex($flex-direction: column, $align-items: center);
    @include cantSelect();
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    cursor: pointer;

    .img-wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 150px;
        background-color: #9db1bc;
        border-radius: 0.75rem;
        overflow: hidden;
        
        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
    p{
        height: 50px;
        font-size: 18px;
        font-weight: 500;
        margin-top: 1rem;
        margin-bottom: 0.5rem;
        text-align: center;
        font-family: "Chivo", sans-serif;
    }
    @media (max-width: 1281px){
        min-width: 11rem;
        p{
            height: 2rem;
            font-size: .9rem;
            font-weight: 500;
            margin-top: .5rem;
            margin-bottom: 0.2rem;
            text-align: center;
            font-family: "Chivo", sans-serif;
        }
    }
}
