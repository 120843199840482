@import "../../../styles/variable";
@import "../../../styles/mixins";

.story {
    &-image {
        width: 40%;

        img {
            width: 100%;
            border-radius: 25px;
        }
    }

    &-content {
        width: 55%;
        padding: 40px;

        h2 {
            text-align: center;
            font-size: 2.5px;
            font-weight: 700;
            letter-spacing: -1px;
            margin-bottom: 20px;
        }

        p,
        span {
            font-size: 1.125rem;
            font-weight: 600;
            text-align: justify;
        }

        span {
            font-weight: 900;
            color: $oliveGreen;
        }
    }
}

@media screen and (max-width: 1281px) {
    .story {
        &-image {
            width: 100%;
            border-radius: 8px;
        }

        &-content {
            width: 100%;
            padding: 0;

            h2{
                font-size: 1.8rem;
                margin-bottom: 10px;
            }

            span {
                margin-left: 7px;
                color: $oliveGreen;
            }

            p {
                font-size: 0.9rem;
            }
        }
    }
}