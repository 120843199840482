@import "../../../styles/variable";
@import "../../../styles/mixins";

@media screen and (min-width: 1282px) {
    .home-product-desktop{
        .home-product {
            &-title {
                width: 100%;
                font-size: 40px;
                font-weight: 700;
                letter-spacing: -1px;
                margin-bottom: 20px;
                text-align: center;
            }
        
            &-items {
                @include flex($align-items: center, $gap: 1rem);
        
        
                .button {
                    padding: 0.5rem 1rem;
                    border-radius: 50%;
                    background-color: #E5E7EB;
                    border: none;
        
                    &:hover {
                        opacity: 0.8;
                    }
        
                    &:active {
                        opacity: 0.6;
                    }
                }
        
                .product-list {
                    display: grid;
                    grid-template-columns: repeat(4, minmax(0, 1fr));
                    gap: 1rem;
                    width: 91.666667%;
        
                }
            }
        
        }
    }
    .home-product-mobile{
        display: none;
    }
}

@media screen and (max-width: 1281px) {
    .home-product-desktop{
        display: none;
    }
    .home-product-mobile{
        
        .home-product {
            &-title {
                width: 100%;
                font-size: 25px;
                font-weight: 700;
                letter-spacing: -1px;
                margin-bottom: 20px;
                text-align: center;
            }
        
            &-items {
                @include flex($align-items: center, $gap: 5px);
        
                .product-list {
                    display: grid;
                    grid-template-columns: repeat(2, minmax(0, 1fr));
                    gap: 8px;
                    width: 100%;
        
                }
            }
        
        }
    }
}
