@import "../../styles/variable";
@import "../../styles/mixins";

.home-container {
    margin: 150px 10% 100px;
    @include flex($justify-content: space-between, $align-items: center, $gap: 150px, $flex-direction: column);
}

@media screen and (max-width: 1281px) {
    .home-container {
        margin: 130px 4% 30px;
        gap: 50px;
    }
}