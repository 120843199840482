@import "../../../styles/variable";
@import "../../../styles/mixins";

.section-container {
    padding: 30px;
    border-radius: 12px;
    box-shadow: $boxShadow;
    --tw-shadow-color: #d1d5db;
    width: 100%;

    &.flex {
        @include flex($gap: 20px, $justify-content: space-between, $align-items: center);
    }

}

@media screen and (max-width: 1281px) {
    .section-container {
        padding: 18px;
        border-radius: 12px;
        box-shadow: $boxShadow;
        --tw-shadow-color: #d1d5db;
        width: 100%;
    
        &.flex {
            @media screen and (max-width: 1281px) {
                flex-direction: column;
                gap: 0px;
            }
        }
    
    }
}